module Home
open EdelweissData.Base.Transfer.Datasets
open EdelweissData.Base.Transfer.Queries
open EdelweissData.Base.Transfer.Queries
open Global
open Elmish
open Utils
open Http
open Fable.React
open Fable.React.Props
open Fulma
open Elmish.SweetAlert

//Types
type Model = {
    Config: Config
    Datasets: DatasetResult list option
}

type Msg =
| DatasetsRetrieved of Result<DatasetResult list , System.Exception>
| DeleteDataset of DatasetResult
| DeleteDatasetConfirmed of Result<DatasetResult, string>
| DatasetDeleted of Result<DatasetResult, System.Exception>

//State
let fetchDatasets(config: Config) =
    promise {
        let fetchDataUrl = sprintf "%s" Routes.PublishedDatasetsGetAll
        let! datasets =
            sendRequestWithoutBody<DatasetResult list>
                fetchDataUrl
                Fetch.Types.HttpMethod.GET
                (Thoth.Json.Decode.list EdelweissData.Thoth.Queries.DatasetResult.decode)
        return datasets
    }

let deleteDataset (config: Config) (dataset: DatasetResult) =
    promise {
        //Delete the Dataset at Edelweiss
        let datasetId = dataset.Id.Id.Unwrap()
        let deleteDatasetUrl = sprintf Routes.PublishedDatasetDelete (datasetId)
        do! sendDeleteRequest deleteDatasetUrl
        return dataset
    }

let init (config: Config) : Model * Cmd<Msg> =

    let fetchCmd =
        Cmd.OfPromise.either
            fetchDatasets
            (config)
            (DatasetsRetrieved << Ok)
            (DatasetsRetrieved << Error)

    { Config = config; Datasets = None }, fetchCmd

let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | DatasetsRetrieved (Ok datasets) ->
        { model with Datasets = Some datasets }, Cmd.none
    | DatasetsRetrieved (Error error) ->
        let msg = error.Message;
        let alert = alertError "Could not Retrieve Datasets" msg
        model, alert
    | DeleteDataset dataset ->
        let handleConfirm =
            function
            | ConfirmAlertResult.Confirmed -> DeleteDatasetConfirmed (Ok dataset)
            | _ -> DeleteDatasetConfirmed (Error "Delete Cancelled")

        let confirmAlert =
            let confirmMsg = sprintf "You are about to delete %s. You won't be able to undo this action" dataset.Name
            ConfirmAlert(confirmMsg, handleConfirm)
                .Title("Are you sure you want to delete this Dataset?")
                .Type(AlertType.Warning)


        let confirm = SweetAlert.Run(confirmAlert)
        model, confirm
    | DeleteDatasetConfirmed datasetResult ->
        match datasetResult with
        | Ok dataset ->
            let deleteCmd =
                Cmd.OfPromise.either
                    (deleteDataset model.Config)
                    (dataset)
                    (DatasetDeleted << Ok)
                    (DatasetDeleted << Error)
            model, deleteCmd
        | Error _ ->
            model, Cmd.none
    | DatasetDeleted (Ok dataset) ->
        match model.Datasets with
        | Some datasets ->
            let newDatasetList = datasets |> List.filter (fun d -> d.Id <> dataset.Id)
            let info = toastInfo "Dataset deleted"
            { model with Datasets = Some newDatasetList }, info
        | None -> model, []
    | DatasetDeleted (Error error) ->
        let message = sprintf "%s" error.Message
        let alert = alertError "Delete Failed" message
        model, alert

//Views
open Fable.React
open Fable.React.Props

let renderDatasets (model: Model) dispatch =
    match model.Datasets with
    | None -> p [ ClassName "empty" ] [ str "Fetching Datasets" ]
    | Some [] -> p [ ClassName "empty" ] [ str "There are no Datasets Available" ]
    | Some dt ->
        table [ ClassName "table is-striped is-hoverable is-fullwidth" ] [
            tbody [] [
            yield tr [] [
                th [] [ str "Name" ]
                th [] [ str "" ]
                th [] [ str "" ]
                th [] [ str "" ]
            ]
            for dataset in dt ->
                let id = dataset.Id.Id.Unwrap()
                let version = dataset.Id.Version.Unwrap()
                let datasetUrl = sprintf "%s/dataexplorer?dataset=%O:%d" model.Config.DataExplorerUrl (id) version
                let schemaUri = sprintf Routes.PublishedDatasetsGetSchema (id)
                let schemaUrl = sprintf "/%s" schemaUri

                let rawDataUrl = sprintf "/api/download/%O/data.xlsx" id

                tr [] [
                    td [] [str dataset.Name]
                    td [ ClassName "has-text-right" ] [
                        a [ Href rawDataUrl; Target "_blank"] [ str "Download" ]
                    ]
                    td [ ClassName "has-text-right" ] [
                        a [ Href datasetUrl; Target "_blank" ] [ str "Explore" ]
                    ]
                    td [ ClassName "has-text-right" ] [
                            a [ OnClick (fun _ -> dispatch (DeleteDataset dataset)) ] [ str "Delete" ]
                    ]
                ]
            ]
        ]


let view (model: Model) (dispatch: Msg -> unit) =

    div [ ] [
        div [ ClassName "heading"] [
            div [ ClassName "column"] [
                h4 [] [str "Datasets"]
            ]
            div [ ClassName "column has-text-right"] [
                a [ ClassName "button is-small is-primary"; Href (Page.ToHash Page.UploadPage) ] [
                    i [ ClassName "fas fa-cloud-upload-alt"] []
                    str "Upload"
                ]
            ]
        ]

        div [ Style [ Padding "10px 20px"] ] [
            renderDatasets model dispatch
        ]

    ]
