module EdelweissData.Base.Transfer.Queries

// ❗👀 This file is used from Fable projects and functions/types used here
// have to be transpile-able to JS. If you make modifications here, please
// check that the SAFE Client apps with Fable still compile. Thanks!

open EdelweissData.Base
open FsToolkit.ErrorHandling

type LimitOffset =
    {
        Limit : int option
        Offset : int option
    }
    static member ToDomain (limitOffset : LimitOffset) =
        { Queries.Limit = limitOffset.Limit
          Queries.Offset = limitOffset.Offset |> Option.defaultValue Queries.LimitOffset.Default.Offset
        }

    static member FromDomain (limitOffset : Queries.LimitOffset) =
        { Limit = limitOffset.Limit
          Offset = Some limitOffset.Offset }


type QueryExpression =
    | Constant of Transfer.Values.PrimitiveValue
    | Node of string * QueryExpression list

    static member ToDomainQueryRelation rel =
      result {
        match rel with
        | "eq" -> return Queries.Equal
        | "gt" -> return Queries.GreaterThan
        | "lt" -> return Queries.LessThan
        | "ge" -> return Queries.GreaterThanOrEqual
        | "le" -> return Queries.LessThanOrEqual
        | "contains" -> return Queries.JsonContains
        | "containedIn" -> return Queries.JsonContainedIn
        | _ -> return! Utils.decodingError "Unknown relation %s" rel
      }

    static member FromDomainQueryRelation = function
      | Queries.Equal -> "eq"
      | Queries.GreaterThan -> "gt"
      | Queries.LessThan -> "lt"
      | Queries.GreaterThanOrEqual -> "ge"
      | Queries.LessThanOrEqual -> "le"
      | Queries.JsonContains -> "contains"
      | Queries.JsonContainedIn -> "containedIn"

    static member ToDomainQueryExpression validColumn expr =
      let verifyColumn column = result {
        if validColumn column then
          return Queries.Column column
        else
          match column with
          | Queries.UserColumn columnName -> return! Utils.decodingError "Invalid user column %s" columnName
          | Queries.SystemColumn columnName -> return! Utils.decodingError "Invalid system column %s" columnName
        }
      let rec toDomain expr =
        result {
          match expr with
          | Constant c ->
              return Queries.Constant (Transfer.Values.PrimitiveValue.ToDomain c)
          | Node (f, exprs) ->
              match f, exprs with
              | "column", [Constant (Transfer.Values.ValueString columnName)] ->
                  return! verifyColumn (Queries.UserColumn columnName)
              | "column", _ -> return! Utils.decodingError "Function column must take exactly one string argument"
              | "systemColumn", [Constant (Transfer.Values.ValueString columnName)] ->
                  return! verifyColumn (Queries.SystemColumn columnName)
              | "systemColumn", _ -> return! Utils.decodingError "Function systemColumn must take exactly one string argument"
              | "cast", [expr; Constant (Transfer.Values.ValueString dataTypeName)] ->
                  let! expr' = toDomain expr
                  let dataType = Transfer.Types.PrimitiveDataTypeName dataTypeName
                  let! dataType' = Transfer.Types.PrimitiveDataType.ToDomain dataType
                  return Queries.Cast (expr', dataType')
              | "cast", _ -> return! Utils.decodingError "Function cast must take exactly two arguments, the second one being a string"
              | "jsonArray", exprs ->
                  let! exprs' = List.traverseResultM toDomain exprs
                  return Queries.JsonArray exprs'
              | "tanimotoSimilarity", [left; right] ->
                  let! left = toDomain left
                  let! right = toDomain right
                  return Queries.TanimotoSimilarity(left, right)
              | "tanimotoSimilarity", _ -> return! Utils.decodingError "Function tanimotoSimilarity must take exactly two arguments, both of them expressions"
              | _, _ -> return! Utils.decodingError "Unknown function %s" f
        }
      toDomain expr

    static member FromDomainQueryExpression expr =
      let rec fromDomain = function
        | Queries.Constant c -> Constant (Transfer.Values.PrimitiveValue.FromDomain c)
        | Queries.Column column ->
            let nodeName, columnName =
              match column with
              | Queries.UserColumn columnName -> "column", columnName
              | Queries.SystemColumn columnName -> "systemColumn", columnName
            Node (nodeName, [Constant (Transfer.Values.ValueString columnName)])
        | Queries.Cast (expr, dataType) ->
            let expr' = fromDomain expr
            let (Transfer.Types.PrimitiveDataTypeName dataTypeName) = Transfer.Types.PrimitiveDataType.FromDomain dataType
            Node ("cast", [expr'; Constant (Transfer.Values.ValueString dataTypeName)])
        | Queries.JsonArray exprs ->
            let exprs' = List.map fromDomain exprs
            Node ("jsonArray", exprs')
        | Queries.TanimotoSimilarity (left, right) ->
            let left = fromDomain left
            let right = fromDomain right
            Node ("tanimotoSimilarity", [left; right])
      fromDomain expr

    static member ToDomainQueryCondition validColumn expr =
      let rec toDomain expr =
        result {
            match expr with
            | Constant _ ->
                return! Utils.decodingError "Constants are not supported in query conditions"
            | Node (f, exprs) ->
                match f, exprs with
                | "and", _ ->
                  let! conds = List.traverseResultM toDomain exprs
                  return Queries.And conds
                | "or", _ ->
                  let! conds = List.traverseResultM toDomain exprs
                  return Queries.Or conds
                | "not", [expr] ->
                    let! cond = toDomain expr
                    return Queries.Not cond
                | "not", _ ->
                    return! Utils.decodingError "Negation takes exactly one argument"
                | ("eq" | "lt" | "le" | "gt" | "ge" | "contains" | "containedIn"), [expr1; expr2] ->
                    let! rel = QueryExpression.ToDomainQueryRelation f
                    let! expr1 = QueryExpression.ToDomainQueryExpression validColumn expr1
                    let! expr2 = QueryExpression.ToDomainQueryExpression validColumn expr2
                    return Queries.Relation (rel, expr1, expr2)
                | ("eq" | "lt" | "le" | "gt" | "ge" | "contains" | "containedIn"), _ ->
                    return! Utils.decodingError "Relations take exactly two arguments"
                | "exactSearch", [expr; Constant (Transfer.Values.ValueString str)] ->
                    let! expr = QueryExpression.ToDomainQueryExpression validColumn expr
                    return Queries.ExactSearch (expr, str)
                | "exactSearch", _ ->
                    return! Utils.decodingError "Function exactSearch must take exactly two arguments, the last one being a string"
                | "fuzzySearch", [expr; Constant (Transfer.Values.ValueString str)] ->
                    let! expr = QueryExpression.ToDomainQueryExpression validColumn expr
                    return Queries.FuzzySearch (expr, str)
                | "fuzzySearch", _ ->
                    return! Utils.decodingError "Function fuzzySearch must take exactly two arguments, the last one being a string"
                | "searchAnywhere", [Constant (Transfer.Values.ValueString str)] ->
                    return Queries.SearchAnywhere str
                | "searchAnywhere", _ ->
                    return! Utils.decodingError "Function searchAnywhere must take exactly one string argument"
                | "substructureSearch", [substructure; superstructure] ->
                    let! substructure = QueryExpression.ToDomainQueryExpression validColumn substructure
                    let! superstructure = QueryExpression.ToDomainQueryExpression validColumn superstructure

                    return Queries.SubstructureSearch (substructure, superstructure)
                | "substructureSearch", _ ->
                    return! Utils.decodingError "Function substructureSearch must take exactly two expression arguments"
                | _, _ ->
                    return! Utils.decodingError "Unknown function %s" f

        }
      toDomain expr


    static member FromDomainQueryCondition cond =
      let rec fromDomain =
        function
        | Queries.And conds ->
            Node ("and", List.map fromDomain conds)
        | Queries.Or conds ->
            Node ("or", List.map fromDomain conds)
        | Queries.Not cond ->
            Node ("not", [fromDomain cond])
        | Queries.Relation (rel, expr1, expr2) ->
            let rel = QueryExpression.FromDomainQueryRelation rel
            let expr1 = QueryExpression.FromDomainQueryExpression expr1
            let expr2 = QueryExpression.FromDomainQueryExpression expr2
            Node (rel, [expr1; expr2])
        | Queries.ExactSearch (expr, str) ->
            let expr1 = QueryExpression.FromDomainQueryExpression expr
            let expr2 = Constant (Transfer.Values.ValueString str)
            Node ("exactSearch", [expr1; expr2])
        | Queries.FuzzySearch (expr, str) ->
            let expr1 = QueryExpression.FromDomainQueryExpression expr
            let expr2 = Constant (Transfer.Values.ValueString str)
            Node ("fuzzySearch", [expr1; expr2])
        | Queries.SearchAnywhere str ->
            Node ("searchAnywhere", [Constant (Transfer.Values.ValueString str)])
        | Queries.SubstructureSearch (substructure, superstructure) ->
            let substructure = QueryExpression.FromDomainQueryExpression substructure
            let superstructure = QueryExpression.FromDomainQueryExpression superstructure
            Node ("substructureSearch", [substructure; superstructure])
      fromDomain cond

type DataQuery =
    { Columns : string list option
      Condition : QueryExpression option
      IncludeAggregations : bool
      AggregationFilters : (string * Identifiers.TermName list) list
      LimitOffset : LimitOffset
      OrderBy : (QueryExpression * Queries.Ordering) list }

    static member ToDomain (dataset : Datasets.PublishedDataset) dataQuery =
      result {
        let! columns =
              result {
                match dataQuery.Columns with
                | None
                | Some [] ->
                    return dataset.Schema.Columns |> Array.toList
                | Some columns ->
                    return!
                      columns
                      |> List.map (Datasets.PublishedDataset.GetColumn dataset)
                      |> List.sequenceResultM
              }

        let validColumn = function
          | Queries.UserColumn columnName -> List.exists (fun (column : Types.Column) -> columnName = column.Name) columns
          | Queries.SystemColumn columnName -> columnName = "id"

        let! condition = result {
          match dataQuery.Condition with
          | None -> return None
          | Some queryExpression ->
              let! query = QueryExpression.ToDomainQueryCondition validColumn queryExpression
              return Some query
        }

        let includeAggregations =
          dataQuery.IncludeAggregations

        let getAggregationPair (columnName : string, buckets : Identifiers.TermName list) = result {
          let! column = Datasets.PublishedDataset.GetColumn dataset columnName
          return (Queries.UserColumn column.Name, buckets)
        }
        let! aggregationPairs = List.traverseResultM getAggregationPair dataQuery.AggregationFilters
        let aggregationFilters = Map.ofList aggregationPairs

        let limitOffset = LimitOffset.ToDomain dataQuery.LimitOffset

        let! orderBy =
          let orderByClause (expr, ordering) = result {
            let! expr = QueryExpression.ToDomainQueryExpression validColumn expr
            return (expr, ordering)
          }
          dataQuery.OrderBy
            |> List.map orderByClause
            |> List.sequenceResultM

        return {
          Queries.DataQuery.Columns = columns
          Queries.DataQuery.Condition = condition
          Queries.DataQuery.IncludeAggregations = includeAggregations
          Queries.DataQuery.AggregationFilters = aggregationFilters
          Queries.DataQuery.LimitOffset = limitOffset
          Queries.DataQuery.OrderBy = orderBy
        }
      }
    static member FromDomain (query : Queries.DataQuery) =
      {
          DataQuery.Columns = Some (List.map (fun (column : Types.Column) -> column.Name) query.Columns)
          DataQuery.Condition = query.Condition |> Option.map QueryExpression.FromDomainQueryCondition
          DataQuery.IncludeAggregations = query.IncludeAggregations
          DataQuery.AggregationFilters =
            query.AggregationFilters
            |> Map.toList
            |> List.map (fun (column, terms) -> (column.ToString (), terms))
          DataQuery.LimitOffset = LimitOffset.FromDomain query.LimitOffset
          DataQuery.OrderBy =
            query.OrderBy
            |> List.map (fun (expr, ord) -> QueryExpression.FromDomainQueryExpression expr, ord)
      }

type DatasetQueryColumn =
    {
      Name : string
      JsonPath : Identifiers.JsonPath
      DataType : Transfer.Types.DataType option
    }
    static member ToDomain column =
        result {
            let! dataType =
              match column.DataType with
              | None -> Ok None
              | Some dataType ->
                  Result.map Some (Types.DataType.ToDomain None dataType)
            return {
              Queries.DatasetQueryColumn.Name = column.Name
              Queries.DatasetQueryColumn.JsonPath = column.JsonPath
              Queries.DatasetQueryColumn.DataType = dataType
            }

        }

    static member FromDomain (column : Queries.DatasetQueryColumn) =
        {
          DatasetQueryColumn.Name = column.Name
          DatasetQueryColumn.JsonPath = column.JsonPath
          DatasetQueryColumn.DataType =
            column.DataType
            |> Option.map (Transfer.Types.DataType.FromDomain >> fst)
        }

type DatasetQuery =
    {
        Columns : DatasetQueryColumn list option
        Condition : QueryExpression option
        IncludeDescription : bool
        IncludeSchema : bool
        IncludeMetadata : bool
        IncludeAggregations : bool
        AggregationFilters : (string * Identifiers.TermName list) list
        LimitOffset : LimitOffset
        OrderBy : (QueryExpression * Queries.Ordering) list
        LatestOnly : bool
    }

    static member ToDomain datasetQuery =
      result {
        let! columns =
          datasetQuery.Columns
          |> Option.defaultValue []
          |> List.traverseResultM DatasetQueryColumn.ToDomain

        let validColumn = function
          | Queries.UserColumn columnName -> List.exists (fun (column : Queries.DatasetQueryColumn) -> column.Name = columnName) columns
          | Queries.SystemColumn columnName ->
            List.contains columnName
              ["id"; "version"; "name"; "created"; "schema"; "metadata"; "description"]

        let getColumn columnName =
          result {
            match List.tryFind (fun (column : Queries.DatasetQueryColumn) -> column.Name = columnName) columns with
            | Some column -> return {
                  Types.Column.Name = columnName
                  Types.Column.Description = sprintf "JSONPath %s" (column.JsonPath.Unwrap())
                  Types.Column.DataType = Types.Primitive Types.TypeString
                  Types.Column.MissingValueIdentifiers = []
                  Types.Column.Indices = Set.empty
                  Types.Column.Visible = true
                  Types.Column.RdfPredicate = None
                  Types.Column.Statistics = Types.NoStatistics
                }
            | None -> return! Utils.decodingError "Query columns do not contain a column named %s" columnName
          }

        let! condition = result {
          match datasetQuery.Condition with
          | None -> return None
          | Some queryExpression ->
              let! query = QueryExpression.ToDomainQueryCondition validColumn queryExpression
              return Some query
        }

        let includeDescription =
          datasetQuery.IncludeDescription

        let includeSchema =
          datasetQuery.IncludeSchema

        let includeMetadata =
          datasetQuery.IncludeMetadata

        let includeAggregations =
          datasetQuery.IncludeAggregations

        let getAggregationPair (columnName : string, buckets : Identifiers.TermName list) = result {
          let! column = getColumn columnName
          return (Queries.UserColumn column.Name, buckets)
        }
        let! aggregationPairs = List.traverseResultM getAggregationPair datasetQuery.AggregationFilters
        let aggregationFilters = Map.ofList aggregationPairs

        let limitOffset =
          LimitOffset.ToDomain datasetQuery.LimitOffset

        let! orderBy =
          let orderByClause (expr, ordering) = result {
            let! expr = QueryExpression.ToDomainQueryExpression validColumn expr
            return (expr, ordering)
          }
          datasetQuery.OrderBy
            |> List.map orderByClause
            |> List.sequenceResultM

        return {
          Queries.DatasetQuery.Columns = columns
          Queries.DatasetQuery.Condition = condition
          Queries.DatasetQuery.IncludeDescription = includeDescription
          Queries.DatasetQuery.IncludeSchema = includeSchema
          Queries.DatasetQuery.IncludeMetadata = includeMetadata
          Queries.DatasetQuery.IncludeAggregations = includeAggregations
          Queries.DatasetQuery.AggregationFilters = aggregationFilters
          Queries.DatasetQuery.LimitOffset = limitOffset
          Queries.DatasetQuery.OrderBy = orderBy
          Queries.DatasetQuery.LatestOnly = datasetQuery.LatestOnly
        }
      }

    static member FromDomain (query : Queries.DatasetQuery) =
      {
          DatasetQuery.Columns = Some (List.map DatasetQueryColumn.FromDomain query.Columns)
          DatasetQuery.Condition = query.Condition |> Option.map QueryExpression.FromDomainQueryCondition
          DatasetQuery.IncludeDescription = query.IncludeDescription
          DatasetQuery.IncludeSchema = query.IncludeSchema
          DatasetQuery.IncludeMetadata = query.IncludeMetadata
          DatasetQuery.IncludeAggregations = query.IncludeAggregations
          DatasetQuery.AggregationFilters =
            query.AggregationFilters
            |> Map.toList
            |> List.map (fun (column, terms) -> (column.ToString (), terms))
          DatasetQuery.LimitOffset = LimitOffset.FromDomain query.LimitOffset
          DatasetQuery.OrderBy =
            query.OrderBy
            |> List.map (fun (expr, ord) -> QueryExpression.FromDomainQueryExpression expr, ord)
          DatasetQuery.LatestOnly = query.LatestOnly
      }

type Aggregation =
    { Buckets : Transfer.Types.AggregationBucket list }

    static member ToDomain (result : Aggregation) =
        { Queries.Aggregation.Buckets =
            result.Buckets
            |> List.map Types.AggregationBucket.ToDomain
        }

    static member FromDomain (result : Queries.Aggregation) =
        { Aggregation.Buckets =
              result.Buckets
              |> List.map Types.AggregationBucket.FromDomain }

type DataResult =
    { Id : int
      Data : Map<string, Transfer.Values.Value> }
    static member ToDomain (result : DataResult) =
        { Queries.DataResult.Id = result.Id
          Queries.DataResult.Data =
            result.Data
            |> Map.map (fun _ -> Transfer.Values.Value.ToDomain)
        }

    static member FromDomain (result : Queries.DataResult) =
        { DataResult.Id = result.Id
          DataResult.Data =
              result.Data
              |> Map.map (fun _ -> Transfer.Values.Value.FromDomain) }

type DataQueryResponse =
    { Total : int
      Offset : int
      Limit : int option
      Results : DataResult list
      Aggregations : Map<string, Aggregation> option }

    static member ToDomain response =
        { Queries.DataQueryResponse.Total = response.Total
          Queries.DataQueryResponse.Offset = response.Offset
          Queries.DataQueryResponse.Limit = response.Limit
          Queries.DataQueryResponse.Results = List.map DataResult.ToDomain response.Results
          Queries.DataQueryResponse.Aggregations = response.Aggregations
                     |> Option.map (Map.map (fun _ -> Aggregation.ToDomain)) }

    static member FromDomain (response : Queries.DataQueryResponse) =
        { DataQueryResponse.Total = response.Total
          DataQueryResponse.Offset = response.Offset
          DataQueryResponse.Limit = response.Limit
          DataQueryResponse.Results = List.map DataResult.FromDomain response.Results
          DataQueryResponse.Aggregations = response.Aggregations
                    |> Option.map (Map.map (fun _ -> Aggregation.FromDomain)) }

type DatasetResult =
    { Id : Identifiers.PublishedDatasetIdAndVersion
      Name : string
      Description : string option
      Created : System.DateTimeOffset
      RowCount : int
      Schema : Transfer.Types.Schema option
      Metadata : Datasets.Metadata option
      Columns : (string * Transfer.Values.Value) list }

    static member ToDomain datasetResult =
        result {
          let! schema =
            result {
              match datasetResult.Schema with
              | None -> return None
              | Some schema ->
                  let! schema = Transfer.Types.Schema.ToDomain schema
                  return Some schema
            }
          return { Queries.DatasetResult.Id = datasetResult.Id
                   Queries.DatasetResult.Name = datasetResult.Name
                   Queries.DatasetResult.Description = datasetResult.Description
                   Queries.DatasetResult.Created = datasetResult.Created
                   Queries.DatasetResult.RowCount = datasetResult.RowCount
                   Queries.DatasetResult.Metadata = datasetResult.Metadata
                   Queries.DatasetResult.Schema = schema
                   Queries.DatasetResult.Columns = datasetResult.Columns
                              |> List.map (fun (name, value) -> (name, Transfer.Values.Value.ToDomain value)) }
        }

    static member FromDomain (result : Queries.DatasetResult) =
        { DatasetResult.Id = result.Id
          DatasetResult.Name = result.Name
          DatasetResult.Description = result.Description
          DatasetResult.Created = result.Created
          DatasetResult.RowCount = result.RowCount
          DatasetResult.Metadata = result.Metadata
          DatasetResult.Schema = Option.map Transfer.Types.Schema.FromDomain result.Schema
          DatasetResult.Columns = result.Columns
                    |> List.map (fun (name, value) -> (name, Transfer.Values.Value.FromDomain value)) }

type DatasetQueryResponse =
    { Total : int
      Offset : int
      Limit : int option
      Results : DatasetResult list
      Aggregations : Map<string, Aggregation> option }

    static member ToDomain response =
        result {
          let! results =
            List.traverseResultM
              DatasetResult.ToDomain
              response.Results

          return { Queries.DatasetQueryResponse.Total = response.Total
                   Queries.DatasetQueryResponse.Offset = response.Offset
                   Queries.DatasetQueryResponse.Limit = response.Limit
                   Queries.DatasetQueryResponse.Results = results
                   Queries.DatasetQueryResponse.Aggregations = response.Aggregations
                              |> Option.map (Map.map (fun _ -> Aggregation.ToDomain)) }
        }

    static member FromDomain (response : Queries.DatasetQueryResponse) =
        { DatasetQueryResponse.Total = response.Total
          DatasetQueryResponse.Offset = response.Offset
          DatasetQueryResponse.Limit = response.Limit
          DatasetQueryResponse.Results = List.map DatasetResult.FromDomain response.Results
          DatasetQueryResponse.Aggregations = response.Aggregations
                    |> Option.map (Map.map (fun _ -> Aggregation.FromDomain)) }
