module EdelweissData.Base.Transfer.Datasets

// ❗👀 This file is used from Fable projects and functions/types used here
// have to be transpile-able to JS. If you make modifications here, please
// check that the SAFE Client apps with Fable still compile. Thanks!

open FsToolkit.ErrorHandling
open EdelweissData.Base

type InProgressDataset =
    { Id : Identifiers.InProgressDatasetId
      Name : string
      Schema : Transfer.Types.Schema option
      Created : System.DateTimeOffset
      Description : string
      Metadata : Datasets.Metadata
      DataSource : Identifiers.PublishedDatasetIdAndVersion option }

    static member ToDomain dataset =
        result {
            let! schema =
              match dataset.Schema with
              | None -> Ok None
              | Some schema ->
                  Result.map Some (Types.Schema.ToDomain schema)
            return {
              Datasets.Id = dataset.Id
              Datasets.Name = dataset.Name
              Datasets.Schema = schema
              Datasets.Created = dataset.Created
              Datasets.Description = dataset.Description
              Datasets.Metadata = dataset.Metadata
              Datasets.DataSource = dataset.DataSource
            }

        }

    static member FromDomain (dataset : Datasets.InProgressDataset) =
        {
          Id = dataset.Id
          Name = dataset.Name
          Schema = Option.map Types.Schema.FromDomain dataset.Schema
          Created = dataset.Created
          Description = dataset.Description
          Metadata = dataset.Metadata
          DataSource = dataset.DataSource
        }

type PublishedDataset =
    { Id : Identifiers.PublishedDatasetIdAndVersion
      Name : string
      Schema : Transfer.Types.Schema
      Created : System.DateTimeOffset
      RowCount: int
      Description : string
      Metadata : Datasets.Metadata }

    static member ToDomain dataset : Result<Datasets.PublishedDataset, Errors.Error> =
        result {
            let! schema = Types.Schema.ToDomain dataset.Schema
            return {
              Datasets.PublishedDataset.Id = dataset.Id
              Datasets.PublishedDataset.Name = dataset.Name
              Datasets.PublishedDataset.Schema = schema
              Datasets.PublishedDataset.Created = dataset.Created
              Datasets.PublishedDataset.RowCount = dataset.RowCount
              Datasets.PublishedDataset.Description = dataset.Description
              Datasets.PublishedDataset.Metadata = dataset.Metadata
            }

        }

    static member FromDomain (dataset : Datasets.PublishedDataset) =
        {
          Id = dataset.Id
          Name = dataset.Name
          Schema = Types.Schema.FromDomain dataset.Schema
          Created = dataset.Created
          Description = dataset.Description
          RowCount = dataset.RowCount
          Metadata = dataset.Metadata
        }
