module Global

open EdelweissData.Base.Identifiers
open Thoth.Json

type Page =
| HomePage
| UploadPage
    static member ToHash page =
        match page with
        | HomePage -> "#/home"
        | UploadPage -> "#/upload"

type Authentication =
    { AccessToken : string option
      Email : string option
      VerifyEmail : bool }
    static member Decoder =
        Decode.object (fun get -> {
            AccessToken = get.Optional.Field "accessToken" Decode.string
            Email = get.Optional.Field "email" Decode.string
            VerifyEmail = get.Optional.Field "verifyEmail" Decode.bool |> Option.defaultValue false
        })

type Config =
    { DataExplorerUrl : string
      Authentication: Authentication option
      ShowDebugControls : bool }

    static member Decoder =
        Decode.object (fun get -> {
            DataExplorerUrl = get.Required.Field "dataExplorerUrl" Decode.string
            Authentication = get.Optional.Field "authentication" Authentication.Decoder
            ShowDebugControls = get.Required.Field "showDebugControls" Decode.bool
        })

type Url = Url of string

type DatasetCollectionId =
    DatasetCollectionId of System.Guid
        member this.Unwrap() =
            let (DatasetCollectionId id) = this
            id

type DatasetData =
    {
        NormalizedDataUri: Url
        SchemaUri: Url
        SourceDataUri : Url
    }
    static member Decoder =
        Decode.object (fun get -> {
            NormalizedDataUri = get.Required.Field "normalizedDataUri" Decode.string |> Url
            SchemaUri = get.Required.Field "schemaUri" Decode.string |> Url
            SourceDataUri = get.Required.Field "sourceDataUri" Decode.string |> Url
        })

    static member Encoder (data : DatasetData) =
        Encode.object
          [ "normalizedDataUri", Encode.string (data.NormalizedDataUri.ToString())
            "schemaUri", Encode.string (data.SchemaUri.ToString())
            "sourceDataUri", Encode.string (data.SourceDataUri.ToString())
          ]

type ValidationFeedback =
    {
        Sheet : string option
        Line : int option
        Column : int option
        Message : string
    }


type ValidationResult =
    {
        Errors : ValidationFeedback list
        Warnings : ValidationFeedback list
        DatasetId: PublishedDatasetId option
    }

module Routes =
    open System
    let PublishedDatasetsGetSchema: Printf.StringFormat<Guid -> string> = "/api/datasets/%O/schema"
    let PublishedDatasetsGetAll = "/api/datasets"
    let PublishedDatasetDelete: Printf.StringFormat<Guid -> string>  = "/api/datasets/%O"