module EdelweissData.Base.Queries

// ❗👀 This file is used from Fable projects and functions/types used here
// have to be transpile-able to JS. If you make modifications here, please
// check that the SAFE Client apps with Fable still compile. Thanks!

open EdelweissData.Base
open FsToolkit.ErrorHandling

type VersionInformation =
    { Version : Identifiers.Version
      Created : System.DateTimeOffset
      Name : string
      Changelog : string }

type PublishedDatasetVersionsInformation =
    { Id : Identifiers.PublishedDatasetId
      Versions : VersionInformation list }

type CreateInProgressDataset =
    { Name : string }

type UpdateInProgressDataset =
    { Name : string option
      Description : string option
      DataSource : Identifiers.PublishedDatasetIdAndVersion option }

type PublishDataset =
    { Changelog : string }

type Ordering =
    | Ascending
    | Descending

type LimitOffset =
    {
        Limit : int option
        Offset : int
    }
    static member Default = {
        Limit = None
        Offset = 0
    }

type QueryRelation =
  | Equal
  | GreaterThan
  | LessThan
  | GreaterThanOrEqual
  | LessThanOrEqual
  | JsonContains
  | JsonContainedIn

type QueryColumn =
  | UserColumn of string
  | SystemColumn of string

  override this.ToString() =
      match this with
      | UserColumn col -> col
      | SystemColumn col -> col

type QueryExpression =
  | Constant of Values.PrimitiveValue
  | Column of QueryColumn
  | Cast of QueryExpression * Types.PrimitiveDataType
  | JsonArray of QueryExpression list
  | TanimotoSimilarity of QueryExpression * QueryExpression


type QueryCondition =
  | And of QueryCondition list
  | Or of QueryCondition list
  | Not of QueryCondition
  | Relation of QueryRelation * QueryExpression * QueryExpression
  | ExactSearch of QueryExpression * string
  | FuzzySearch of QueryExpression * string
  | SearchAnywhere of string
  | SubstructureSearch of substructure: QueryExpression * superstructure: QueryExpression


type DataQuery = {
  Columns : Types.Column list
  Condition : QueryCondition option
  IncludeAggregations : bool
  AggregationFilters : Map<QueryColumn, Identifiers.TermName list>
  LimitOffset : LimitOffset
  OrderBy : (QueryExpression * Ordering) list
}
  with
    static member Default =
        { Columns = []
          Condition = None
          IncludeAggregations = false
          AggregationFilters = Map.empty
          LimitOffset = LimitOffset.Default
          OrderBy = []
         }

type DatasetQueryColumn = {
    Name : string
    JsonPath : Identifiers.JsonPath
    DataType : Types.DataType option
}

type DatasetQuery =
    {
      Columns : DatasetQueryColumn list
      Condition : QueryCondition option
      IncludeDescription : bool
      IncludeSchema : bool
      IncludeMetadata : bool
      IncludeAggregations : bool
      AggregationFilters : Map<QueryColumn, Identifiers.TermName list>
      LimitOffset : LimitOffset
      OrderBy : (QueryExpression * Ordering) list
      LatestOnly : bool
    }
        static member Default = {
            Columns = []
            Condition = None
            IncludeDescription = false
            IncludeSchema = false
            IncludeMetadata = false
            IncludeAggregations = false
            AggregationFilters = Map.empty
            LimitOffset = LimitOffset.Default
            OrderBy = []
            LatestOnly = true
        }


type Aggregation =
    { Buckets : Types.AggregationBucket list }

type DataResult =
    { Id : int
      Data : Map<string, Values.Value> }

type DataQueryResponse =
    { Total : int
      Offset : int
      Limit : int option
      Results : DataResult list
      Aggregations : Map<string, Aggregation> option }

type DatasetResult =
    { Id : Identifiers.PublishedDatasetIdAndVersion
      Name : string
      Description : string option
      Created : System.DateTimeOffset
      RowCount : int
      Schema : Types.Schema option
      Metadata : Datasets.Metadata option
      Columns : (string * Values.Value) list }

type DatasetQueryResponse =
    { Total : int
      Offset : int
      Limit : int option
      Results : DatasetResult list
      Aggregations : Map<string, Aggregation> option }

type AuthUser =
    { Email : Identifiers.Email
      CanWrite : bool }

type AuthGroup =
    { Name : string
      CanWrite : bool }

type DatasetPermissions =
    { Id : Identifiers.DatasetId
      IsPublic : bool
      Users : AuthUser list
      Groups : AuthGroup list }

type OidcConfig =
    { Domain : string
      Audience : string
      NativeClientId : string
      WebClientId : string }

type ErrorResponse =
    // ErrorType is optional because it would be sad to lose the message just because ErrorType cannot be deserialized.
    { ErrorType : Errors.TransferError option
      Message : string }
