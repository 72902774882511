module EdelweissData.Thoth.Types

// ❗👀 Make sure that the code below is the same in EdelweissData.Thoth.Net and EdelweissData.Thoth.Fable

open EdelweissData.Base.Transfer.Types
open Thoth.Json

module AggregationBucket =
    let decode : Decoder<AggregationBucket> =
        Decode.object (fun get ->
            { DocCount = get.Required.Field "docCount" Decode.int
              TermName = get.Optional.Field "termName" Decode.string })

    let encode (data : AggregationBucket) =
        Encode.object [ "docCount", Encode.int data.DocCount
                        "termName", Encode.option Encode.string data.TermName ]
    
    let fromStringArray = Utils.fromString (Array.map AggregationBucket.ToDomain) (Decode.array decode)

module Statistics =
    let decode : Decoder<Statistics> =
        Decode.object (fun get ->
            { Mean = get.Optional.Field "mean" Decode.float
              StandardDeviation = get.Optional.Field "standardDeviation" Decode.float
              Min = get.Optional.Field "min" Decode.float
              Max = get.Optional.Field "max" Decode.float
              Terms = get.Optional.Field "terms" (Decode.array AggregationBucket.decode) })

    let encode (stats : Statistics) =
        Encode.object [ "mean", Encode.option Encode.float stats.Mean
                        "standardDeviation", Encode.option Encode.float stats.StandardDeviation
                        "min", Encode.option Encode.float stats.Min
                        "max", Encode.option Encode.float stats.Max
                        "terms", Encode.option (Array.map AggregationBucket.encode >> Encode.array) stats.Terms ]

module Column =

    let decode : Decoder<Column> =
        Decode.object (fun get ->
            { Name = get.Required.Field "name" Decode.string
              Description = get.Optional.Field "description" Decode.string
              DataType = get.Required.Field "dataType" (Decode.string |> Decode.map DataTypeName)
              ArrayValueSeparator = get.Optional.Field "arrayValueSeparator" Decode.string
              MissingValueIdentifiers = get.Optional.Field "missingValueIdentifiers" (Decode.list Decode.string) |> Option.defaultValue []
              Indices = get.Optional.Field "indices" (Decode.list (Decode.string |> Decode.map IndexType))
              Visible = get.Optional.Field "visible" Decode.bool
              RdfPredicate = get.Optional.Field "rdfPredicate" Decode.string
              Statistics = get.Optional.Field "statistics" Statistics.decode })

    let encode (column : Column) =
        Encode.object [ "name", Encode.string column.Name
                        "description", Encode.option (Encode.string) column.Description
                        "dataType", (let (DataTypeName dataType) = column.DataType in Encode.string dataType)
                        "arrayValueSeparator", Encode.option (Encode.string) column.ArrayValueSeparator
                        "missingValueIdentifiers", column.MissingValueIdentifiers
                                                   |> List.map Encode.string
                                                   |> Encode.list
                        "indices", Encode.option (List.map (fun (IndexType indexType) -> Encode.string indexType) >> Encode.list) column.Indices
                        "visible", Encode.option Encode.bool column.Visible
                        "rdfPredicate", Encode.option Encode.string column.RdfPredicate
                        "statistics", Encode.option Statistics.encode column.Statistics ]

module Schema =
    let decode : Decoder<Schema> =
        Decode.object (fun get ->
            { Columns = get.Required.Field "columns" (Decode.array Column.decode) })

    let encode (schema : Schema) =
        Encode.object [ "columns", schema.Columns |> Array.map Column.encode |> Encode.array ]

    let toString = Utils.toString Schema.FromDomain encode

    let toStringPretty = Utils.toStringPretty Schema.FromDomain encode

    let fromString = Utils.fromStringResult Schema.ToDomain decode
