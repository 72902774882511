module EdelweissData.Thoth.Utils

// ❗👀 Make sure that the code below is the same in EdelweissData.Thoth.Net and EdelweissData.Thoth.Fable

open EdelweissData.Base
open FsToolkit.ErrorHandling
open Thoth.Json

let encodeInt (n : int64) =
    box n

let toStringCustom spaces (fromDomain : 'domain -> 'transfer) (encoder : 'transfer -> JsonValue) (domain : 'domain) : string =
    let transfer = fromDomain domain
    let json = encoder transfer
    Encode.toString spaces json

let toString<'domain, 'transfer> : (('domain -> 'transfer) -> ('transfer -> JsonValue) -> ('domain) -> string) = toStringCustom 0

let toStringPretty<'domain, 'transfer> : (('domain -> 'transfer) -> ('transfer -> JsonValue) -> ('domain) -> string) = toStringCustom 2

let fromStringResult (toDomain : 'transfer -> Result<'domain, Errors.Error>) (decoder : Decoder<'transfer>) (str : string) : Result<'domain, Errors.Error> =
    result {
        let! decodedJson =
            Decode.fromString decoder str
            |> Result.mapError (fun err -> Errors.ThothDecodingError (err.ToString()))
        let! domain = toDomain decodedJson
        return domain
    }

let fromString (toDomain : 'transfer -> 'domain) (decoder : Decoder<'transfer>) (str : string) : Result<'domain, Errors.Error> =
    fromStringResult (toDomain >> Result.Ok) decoder str
