module Design

open EdelweissData.Base.Transfer.Datasets
open EdelweissData.Base.Identifiers
open EdelweissData.Base
open EdelweissData.Base.Transfer.Queries
open System
open Global
open Types
open Fable.React
open Fable.React.Props

type DesignButton =
| LoadingBtn
| HomeBtn
| SelectFilesBtn
| UploadingBtn


let auth = { Authentication.AccessToken = None; Authentication.Email = Some "someone@example.com"; Authentication.VerifyEmail = true }
let config: Config = { Config.DataExplorerUrl = "http://localhost:30070"; Config.ShowDebugControls = true; Config.Authentication = Some auth }

let datasets: DatasetResult list =
    [
        for i in 1 .. 10 do
        yield
            {
                Id = { PublishedDatasetIdAndVersion.Id = PublishedDatasetId (Guid.NewGuid()); Version = Identifiers.Version 1 }
                Name = "Sample " + string i
                Description = None
                Created = DateTimeOffset.Now
                Metadata = None
                Columns = []
                Schema = None
                RowCount = 0
            }
    ]

let tasks =
    [
        for i in 1..10 do
        yield {
            Upload.UploadTask.File = Browser.Dom.File.Create([||], "file.csv", null)
            Upload.UploadTask.Status = Upload.Pending
        }
    ]

let buildPageState button: Page * PageModel =
    match button with
    | LoadingBtn -> HomePage, LoadingConfig
    | HomeBtn -> HomePage, HomeModel { Config = config; Datasets = Some datasets }
    | SelectFilesBtn -> UploadPage, UploadModel { Config = config; Tasks = tasks; Step = Upload.Step.SelectFileStep }
    | UploadingBtn -> UploadPage, UploadModel { Config = config; Tasks = tasks; Step = Upload.Step.UploadingStep }

let renderStateButtons model (dispatch: Msg -> unit) =

    let dispachMsg (msg : Msg) _ =
        dispatch msg

    div [ ClassName "design-mode-buttons" ]
        [ button [ ClassName "button is-small is-white" ; OnClick (dispachMsg (GoToDesignModeState (buildPageState LoadingBtn))) ] [ str "Loading" ]
          button [ ClassName "button is-small is-white" ; OnClick (dispachMsg (GoToDesignModeState (buildPageState HomeBtn))) ] [ str "Home" ]
          button [ ClassName "button is-small is-white" ; OnClick (dispachMsg (GoToDesignModeState (buildPageState SelectFilesBtn))) ] [ str "Select Files" ]
          button [ ClassName "button is-small is-white" ; OnClick (dispachMsg (GoToDesignModeState (buildPageState UploadingBtn))) ] [ str "Uploading" ]]