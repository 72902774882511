module EdelweissData.Base.Transfer.Types

// ❗👀 This file is used from Fable projects and functions/types used here
// have to be transpile-able to JS. If you make modifications here, please
// check that the SAFE Client apps with Fable still compile. Thanks!

open EdelweissData.Base
open FsToolkit.ErrorHandling

type PrimitiveDataType =
    | PrimitiveDataTypeName of string

    static member ToDomain (PrimitiveDataTypeName dataType) =
        match dataType with
        | Utils.CaseInsensitiveEqual "xsd:string" -> Ok Types.TypeString
        | Utils.CaseInsensitiveEqual "xsd:anyURI" -> Ok Types.TypeUri
        | Utils.CaseInsensitiveEqual "xsd:boolean" -> Ok Types.TypeBool
        | Utils.CaseInsensitiveEqual "xsd:integer" -> Ok Types.TypeInt
        | Utils.CaseInsensitiveEqual "xsd:double" -> Ok Types.TypeDouble
        | Utils.CaseInsensitiveEqual "edelweiss:datasetid" -> Ok Types.TypeDatasetId
        | Utils.CaseInsensitiveEqual "cheminf:CHEMINF_000018" -> Ok Types.TypeSmiles
        | x -> Error (Errors.CouldNotParsePrimitiveDataType x)

    static member FromDomain = function
        | Types.TypeString -> PrimitiveDataTypeName "xsd:string"
        | Types.TypeUri -> PrimitiveDataTypeName "xsd:anyURI"
        | Types.TypeInt -> PrimitiveDataTypeName "xsd:integer"
        | Types.TypeDouble -> PrimitiveDataTypeName "xsd:double"
        | Types.TypeBool -> PrimitiveDataTypeName "xsd:boolean"
        | Types.TypeDatasetId -> PrimitiveDataTypeName "edelweiss:datasetid"
        | Types.TypeSmiles -> PrimitiveDataTypeName "cheminf:CHEMINF_000018"


type DataType =
    | DataTypeName of string


    static member ToDomain arrayValueSeparator (DataTypeName dataType) =
        match PrimitiveDataType.ToDomain (PrimitiveDataTypeName dataType) with
        | Ok primitive -> Ok (Types.Primitive primitive)
        | Error _ ->
            // Recover - it's not a primitive.
            match dataType with
            | Utils.StartsWith "xsd:list " listType ->
                match arrayValueSeparator with
                | None -> Error (Errors.CouldNotParseDataType "missing array value separator")
                | Some separator ->
                    PrimitiveDataType.ToDomain (PrimitiveDataTypeName listType)
                    |> Result.map (fun primitiveType -> Types.TypeArray (separator, primitiveType))
            | _ -> Error (Errors.CouldNotParseDataType dataType)

    static member FromDomain = function
        | Types.Primitive primitiveType ->
            let (PrimitiveDataTypeName dataType) = PrimitiveDataType.FromDomain primitiveType
            (DataTypeName dataType), None
        | Types.TypeArray (valueSeparator, arrayType) ->
            let (PrimitiveDataTypeName dataType) = PrimitiveDataType.FromDomain arrayType
            (DataTypeName ("xsd:list " + dataType)), Some valueSeparator

type IndexType =
    | IndexType of string

    static member ToDomain (IndexType indexType) =
        match indexType with
            | "full-text" -> Ok Types.FullTextSearchIndex
            | "terms-aggregation" -> Ok Types.TermsAggregationIndex
            | "substructure-search" -> Ok Types.SubstructureSearchIndex
            | "chemical-similarity-search" -> Ok Types.ChemicalSimilaritySearchIndex
            | other -> Error (Errors.CouldNotParseIndexType other)

    static member FromDomain = function
        | Types.FullTextSearchIndex -> IndexType "full-text"
        | Types.TermsAggregationIndex -> IndexType "terms-aggregation"
        | Types.SubstructureSearchIndex -> IndexType "substructure-search"
        | Types.ChemicalSimilaritySearchIndex -> IndexType "chemical-similarity-search"

type AggregationBucket =
    { DocCount : int
      TermName : string option }

    static member ToDomain bucket =
        { Types.DocCount = bucket.DocCount
          Types.TermName = bucket.TermName
                     |> Option.map Identifiers.TermId
                     |> Option.defaultValue Identifiers.Null }

    static member FromDomain (bucket : Types.AggregationBucket) =
        { AggregationBucket.DocCount = bucket.DocCount
          AggregationBucket.TermName =
              match bucket.TermName with
              | Identifiers.TermId s -> Some s
              | Identifiers.Null -> None }

type Statistics =
    { Mean : double option
      StandardDeviation : double option
      Min : double option
      Max : double option
      Terms : AggregationBucket array option }

    static member Default = {
      Mean = None
      StandardDeviation = None
      Min = None
      Max = None
      Terms = None
    }

    static member ToDomain datatype stats =
        match datatype with
        | Types.Primitive (Types.TypeString | Types.TypeUri | Types.TypeDatasetId) ->
            stats.Terms
            |> Option.map ((Array.map AggregationBucket.ToDomain) >> Types.TermsStatistics)
            |> Option.defaultValue Types.NoStatistics
        | Types.Primitive Types.TypeDouble ->
            match stats.Mean, stats.StandardDeviation, stats.Min, stats.Max with
            | Some mean, Some sd, Some min, Some max ->
                { Types.Mean = mean
                  Types.StandardDeviation = sd
                  Types.Min = min
                  Types.Max = max }
                |> Types.DoubleStatistics
            | _ ->
                Types.NoStatistics
        | Types.Primitive Types.TypeInt ->
            match stats.Mean, stats.StandardDeviation, stats.Min, stats.Max with
            | Some mean, Some sd, Some min, Some max ->
                { Types.Mean = mean
                  Types.StandardDeviation = sd
                  Types.Min = System.Math.Round min |> int64
                  Types.Max = System.Math.Round max |> int64 }
                |> Types.IntStatistics
            | _ ->
                Types.NoStatistics
        | Types.TypeArray _
        | Types.Primitive Types.TypeSmiles
        | Types.Primitive Types.TypeBool ->
            Types.NoStatistics

    static member FromDomain = function
        | Types.NoStatistics -> Statistics.Default
        | Types.IntStatistics stats ->
            {Statistics.Default with
                              Mean = Some stats.Mean
                              StandardDeviation = Some stats.StandardDeviation
                              Min = Some (double stats.Min)
                              Max = Some (double stats.Max)}
        | Types.DoubleStatistics stats ->
            {Statistics.Default with
                              Mean = Some stats.Mean
                              StandardDeviation = Some stats.StandardDeviation
                              Min = Some stats.Min
                              Max = Some stats.Max}
        | Types.TermsStatistics terms ->
            {Statistics.Default with Terms = Some (Array.map AggregationBucket.FromDomain terms)}

type Column =
    { Name : string
      Description : string option
      DataType : DataType
      ArrayValueSeparator : string option
      MissingValueIdentifiers : string list
      Indices : IndexType list option
      Visible : bool option
      RdfPredicate : string option
      Statistics : Statistics option }

    static member ToDomain column =
        result {
            let! dataType = DataType.ToDomain column.ArrayValueSeparator column.DataType
            let! indices =
              column.Indices
              |> Option.defaultValue []
              |> List.traverseResultM IndexType.ToDomain
              |> Result.map Set.ofList
            let statistics = column.Statistics
                              |> Option.map (Statistics.ToDomain dataType)
                              |> Option.defaultValue Types.NoStatistics
            return
                { Types.Name = column.Name
                  Types.Description = column.Description |> Option.defaultValue ""
                  Types.DataType = dataType
                  Types.MissingValueIdentifiers = column.MissingValueIdentifiers
                  Types.Indices = indices
                  Types.Visible = Option.defaultValue true column.Visible
                  Types.RdfPredicate = column.RdfPredicate
                  Types.Statistics = statistics }
        }

    static member FromDomain (column : Types.Column) =
        let dataType, arrayValueSeparator = DataType.FromDomain column.DataType
        {
          Name = column.Name
          Description = Some column.Description
          DataType = dataType
          ArrayValueSeparator = arrayValueSeparator
          MissingValueIdentifiers = column.MissingValueIdentifiers
          Indices = Some [for index in column.Indices do yield IndexType.FromDomain index]
          Visible = Some column.Visible
          RdfPredicate = column.RdfPredicate
          Statistics = Some (Statistics.FromDomain column.Statistics)
        }

type Schema =
    { Columns : Column array }

    static member ToDomain schema  =
        result {
            let! columns =
                schema.Columns
                |> Array.toList
                |> List.traverseResultM Column.ToDomain

            return { Types.Columns = columns |> Array.ofList }
        }

    static member FromDomain (schema : Types.Schema) =
        { Columns = Array.map Column.FromDomain schema.Columns }
