module EdelweissData.Base.Transfer.Values

// ❗👀 This file is used from Fable projects and functions/types used here
// have to be transpile-able to JS. If you make modifications here, please
// check that the SAFE Client apps with Fable still compile. Thanks!

open EdelweissData.Base

type PrimitiveValue =
    | ValueNull
    | ValueString of string
    | ValueUri of string
    | ValueInt of int64
    | ValueDouble of double
    | ValueBool of bool
    | ValueDatasetId of System.Guid
    | ValueSmiles of original: string * canonical: string option * similarity : float option

    static member ToDomain = function
        | ValueNull -> Values.ValueNull
        | ValueString str -> Values.ValueString str
        | ValueUri uri -> Values.ValueUri uri
        | ValueInt x -> Values.ValueInt x
        | ValueDouble x -> Values.ValueDouble x
        | ValueBool b -> Values.ValueBool b
        | ValueDatasetId id -> Values.ValueDatasetId id
        | ValueSmiles (original, canonical, similarity) -> Values.ValueSmiles (original, canonical, similarity)

    static member FromDomain = function
        | Values.ValueNull -> ValueNull
        | Values.ValueString str -> ValueString str
        | Values.ValueUri uri -> ValueUri uri
        | Values.ValueInt x -> ValueInt x
        | Values.ValueDouble x -> ValueDouble x
        | Values.ValueBool b -> ValueBool b
        | Values.ValueDatasetId id -> ValueDatasetId id
        | Values.ValueSmiles (original, canonical, similarity) -> ValueSmiles (original, canonical, similarity)

type Value =
    | ValuePrimitive of PrimitiveValue
    | ValueArray of PrimitiveValue []

    static member ToDomain = function
        | ValuePrimitive value -> Values.ValuePrimitive (PrimitiveValue.ToDomain value)
        | ValueArray values -> Values.ValueArray (Array.map PrimitiveValue.ToDomain values)

    static member FromDomain = function
        | Values.ValuePrimitive value -> ValuePrimitive (PrimitiveValue.FromDomain value)
        | Values.ValueArray values -> ValueArray (Array.map PrimitiveValue.FromDomain values)
