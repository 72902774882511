module EdelweissData.Thoth.Values

// ❗👀 Make sure that the code below is the same in EdelweissData.Thoth.Net and EdelweissData.Thoth.Fable

open EdelweissData.Base
open EdelweissData.Base.Transfer.Values
open Thoth.Json

module PrimitiveValue =

    let decode =
        Decode.oneOf [ Decode.nil ValueNull
                       Decode.int64 |> Decode.map ValueInt
                       Decode.float |> Decode.map ValueDouble
                       Decode.bool |> Decode.map ValueBool
                       Decode.string |> Decode.map ValueString ]

    let typedDecode primitiveDataType =
        let typeDecoder =
            match primitiveDataType with
            | Types.TypeString -> Decode.string |> Decode.map ValueString
            | Types.TypeUri -> Decode.string |> Decode.map ValueUri
            | Types.TypeInt -> Decode.int64 |> Decode.map ValueInt
            | Types.TypeDouble -> Decode.float |> Decode.map ValueDouble
            | Types.TypeBool -> Decode.bool |> Decode.map ValueBool
            | Types.TypeDatasetId -> Decode.guid |> Decode.map ValueDatasetId
            | Types.TypeSmiles ->
               Decode.object (fun get ->
                    let original = get.Optional.Field "original" Decode.string
                    let canonical = get.Optional.Field "canonical" Decode.string
                    let similarity = get.Optional.Field "similarity" Decode.float
                    match original with
                    | Some original -> ValueSmiles (original, canonical, similarity)
                    | None -> ValueNull
                )
        Decode.oneOf [ Decode.nil ValueNull
                       typeDecoder ]

    let encode =
        function
        | ValueNull -> Encode.nil
        | ValueString str -> Encode.string str
        | ValueUri uri -> Encode.string uri
        | ValueInt n -> Utils.encodeInt n
        | ValueDouble x -> Encode.float x
        | ValueBool b -> Encode.bool b
        | ValueDatasetId uuid -> Encode.guid uuid
        | ValueSmiles (original, canonical, similarity) ->
            Encode.object
                [ "original", Encode.string original
                  "canonical", Encode.option Encode.string canonical
                  if similarity.IsSome then
                    "similarity", Encode.option Encode.float similarity
                  ]

module Value =

    let decode : Decoder<Value> =
        Decode.oneOf [ Decode.array PrimitiveValue.decode |> Decode.map ValueArray
                       PrimitiveValue.decode |> Decode.map ValuePrimitive ]

    let typedDecode =
        function
        | Types.Primitive primitiveDataType ->
            PrimitiveValue.typedDecode primitiveDataType |> Decode.map ValuePrimitive
        | Types.TypeArray(_, primitiveDataType) ->
            Decode.array (PrimitiveValue.typedDecode primitiveDataType) |> Decode.map ValueArray

    let encode =
        function
        | ValuePrimitive v -> PrimitiveValue.encode v
        | ValueArray vs -> Array.map PrimitiveValue.encode vs |> Encode.array
