module App

open Elmish
open Elmish.React
open Elmish.Navigation


#if DEBUG
open Elmish.Debug
open Elmish.HMR
#endif

open State
open View


Program.mkProgram init update view
|> Program.toNavigable (UrlParser.parseHash State.pageParser) State.urlUpdate
#if DEBUG
|> Program.withConsoleTrace
|> Program.withDebugger
#endif
|> Program.withReactBatched "app"
#if DEBUG
|> Program.withDebugger
#endif
|> Program.run
