module State

open Global
open Types
open Thoth.Fetch
open Elmish
open Elmish.UrlParser
open Utils
open Elmish.Navigation
open Http

let fetchConfig() = Fetch.fetchAs("/api/init", Config.Decoder)
let fetchAuth() = Fetch.fetchAs ("/api/auth", Authentication.Decoder)

let urlUpdate (pageOption : Page option) (model: Types.Model) =
    match pageOption with
    | Some HomePage  -> { model with Page = HomePage }, Cmd.ofMsg SetupPage
    | Some UploadPage -> { model with Page = UploadPage }, Cmd.ofMsg SetupPage
    | None ->
        Browser.Dom.console.error("Error parsing url")
        let modifyUrlCmd = Navigation.modifyUrl (Page.ToHash model.Page)
        model, Cmd.batch [ modifyUrlCmd; Cmd.ofMsg SetupPage ]


let init (pageOption: Page option) : Model * Cmd<Msg> =

    let page = pageOption |> Option.defaultValue HomePage
    let initialModel = { Config = None; Page = page; State = LoadingConfig }

    let model, setupCmd = urlUpdate pageOption initialModel

    model, setupCmd

let pageParser: Parser<(Page -> Page),Page> =
    oneOf [
        map HomePage (s "home")
        map UploadPage (s "upload")
    ]

// The id token eventually expires so poll the auth endpoint to get a new one every hour.
let subscribe (model : Model) : Cmd<Msg> =
    let sub dispatch =
        let schedule () = dispatch ScheduleFetchAuth
        Browser.Dom.window.setInterval (schedule, 3600 * 1000) |> ignore
    Cmd.ofSub sub

let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | SetupPage ->
        match model.Config with
        | None ->
            let loadConfigCmd =
                Cmd.OfPromise.either
                    fetchConfig
                    ()
                    (Ok >> ConfigRetrieved)
                    (Error >> ConfigRetrieved)
            model, loadConfigCmd
        | Some config ->
            match config.Authentication, model.Page with
            | None, _ ->
                { model with State = LoginModel }, Cmd.none
            | Some _, HomePage ->
                let state, cmd = Home.init config
                { model with State = HomeModel state}, Cmd.map HomeMsg cmd
            | Some _, UploadPage ->
                let state, cmd = Upload.init config
                { model with State = UploadModel state }, Cmd.map UploadMsg cmd

    | ConfigRetrieved (Ok config) ->
        { model with Config = Some config }, Cmd.ofMsg SetupPage
    | ConfigRetrieved (Error error) ->
        let msg = sprintf "Can not retrieve configuration: %O" error
        model, alertError "Config Error" msg
    | HomeMsg msg ->
        match model.State with
        | HomeModel m ->
            let newModel, cmd = Home.update msg m
            {model with State = HomeModel newModel }, Cmd.map HomeMsg cmd
        | _ -> model, Cmd.none
    | UploadMsg msg ->
        match model.State with
        | UploadModel m ->
            let newModel, cmd = Upload.update msg m
            {model with State = UploadModel newModel }, Cmd.map UploadMsg cmd
        | _ -> model, Cmd.none
    | GoToDesignModeState (page, state) ->
        { model with Page = page; State = state }, Cmd.Empty
    | ScheduleFetchAuth ->
        let cmd =
            Cmd.OfPromise.either
                    fetchAuth
                    ()
                    (Ok >> AuthRetrieved)
                    (Error >> AuthRetrieved)
        model, cmd
    | AuthRetrieved (Ok auth) ->
        let newConfig = model.Config |> Option.map (fun c -> { c with Authentication = Some auth })

        { model with Config = newConfig }, Cmd.none
    | AuthRetrieved (Error msg) ->
        consoleError (sprintf "Could not retrieve Authentication Token: %s" msg.Message)
        model, Cmd.none