module EdelweissData.Thoth.Queries

// ❗👀 Make sure that the code below is the same in EdelweissData.Thoth.Net and EdelweissData.Thoth.Fable

open EdelweissData.Base
open EdelweissData.Base.Identifiers
open EdelweissData.Base.Queries
open EdelweissData.Base.Transfer.Values
open EdelweissData.Base.Transfer.Queries
open Thoth.Json
open FsToolkit.ErrorHandling
module ThothIdentifiers = EdelweissData.Thoth.Identifiers
module ThothTypes = EdelweissData.Thoth.Types
module ThothValues = EdelweissData.Thoth.Values
module ThothDatasets = EdelweissData.Thoth.Datasets


module VersionInformation =

    let decode =
        Decode.object (fun get ->
            { Version = get.Required.Field "version" ThothIdentifiers.Version.decode
              Created = get.Required.Field "created" Decode.datetimeOffset
              Name = get.Required.Field "name" Decode.string
              Changelog = get.Required.Field "changelog" Decode.string })

    let encode (data : VersionInformation) =
        Encode.object [ "version", ThothIdentifiers.Version.encode data.Version
                        "created", Encode.datetimeOffset data.Created
                        "name", Encode.string data.Name
                        "changelog", Encode.string data.Changelog ]

module PublishedDatasetVersionsInformation =

    let decode : Decoder<PublishedDatasetVersionsInformation> =
        Decode.object (fun get ->
            { Id = get.Required.Field "id" ThothIdentifiers.PublishedDatasetId.decode
              Versions = get.Required.Field "versions" (Decode.list VersionInformation.decode) })

    let encode (data : PublishedDatasetVersionsInformation) =
        Encode.object [ "id", ThothIdentifiers.PublishedDatasetId.encode data.Id
                        "versions",
                        data.Versions
                        |> List.map VersionInformation.encode
                        |> Encode.list ]
    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode
    let fromString = Utils.fromString id decode

module CreateInProgressDataset =
    let decode : Decoder<CreateInProgressDataset> = Decode.object (fun get -> { Name = get.Required.Field "name" Decode.string })
    let encode (data : CreateInProgressDataset) = Encode.object [ "name", Encode.string data.Name ]
    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode
    let fromString = Utils.fromString id decode

module UpdateInProgressDataset =
    let decode : Decoder<UpdateInProgressDataset> = Decode.object (fun get ->
        { Name = get.Optional.Field "name" Decode.string
          Description = get.Optional.Field "description" Decode.string
          DataSource = get.Optional.Field "dataSource" ThothIdentifiers.PublishedDatasetIdAndVersion.decode })
    let encode (data : UpdateInProgressDataset) =
        Encode.object [ "name", Encode.option Encode.string data.Name
                        "description", Encode.option Encode.string data.Description
                        "dataSource", Encode.option ThothIdentifiers.PublishedDatasetIdAndVersion.encode data.DataSource ]
    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode
    let fromString = Utils.fromString id decode

module PublishDataset =
    let decode : Decoder<PublishDataset> = Decode.object (fun get -> { Changelog = get.Required.Field "changelog" Decode.string })
    let encode (data : PublishDataset) = Encode.object [ "changelog", Encode.string data.Changelog ]
    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode
    let fromString = Utils.fromString id decode


module QueryExpression =

    let decode =
        let constantDecoder =
            Decode.map Constant ThothValues.PrimitiveValue.decode
        // We have to be a bit careful here because we have a recursive decoder and
        // will get a stack overflow if things aren't sufficiently lazily defined
        let rec nodeDecoder path value =
            Decode.keyValuePairs (Decode.list decoder)
            |> Decode.andThen (function
               | [(f, exprs)] -> Decode.succeed (Node (f, exprs))
               | _ -> Decode.fail "Expected an object with a single key and an array as its value"
            )
            |> fun d -> d path value
        and decoder path value =
            Decode.oneOf [constantDecoder; nodeDecoder] path value
        decoder

    let rec encode =
        function
        | Constant primitiveValue -> ThothValues.PrimitiveValue.encode primitiveValue
        | Node (f, exprs) -> Encode.object [(f, List.map encode exprs |> Encode.list)]

module Ordering =
    let decode =
        Decode.string
        |> Decode.andThen (
            function
            | "ascending" -> Decode.succeed Ascending
            | "descending" -> Decode.succeed Descending
            | _ -> Decode.fail "Expected 'ascending' or 'descending'"
        )

    let encode =
        function
        | Ascending -> Encode.string "ascending"
        | Descending -> Encode.string "descending"

module LimitOffset =

    let decode : Decoder<LimitOffset> =
        Decode.object (fun get ->
            { Offset = get.Optional.Field "offset" (Decode.option Decode.int) |> Option.defaultValue None
              Limit = get.Optional.Field "limit" (Decode.option Decode.int) |> Option.defaultValue None })


    let fromString = Utils.fromString Transfer.Queries.LimitOffset.ToDomain decode

// TODO: Make AggregationFilter & OrderBy separate data types
// with the four functions below as their decoders & encoders

let private aggregationFilterDecoder =
    Decode.object (fun get ->
        let columnName = get.Required.Field "columnName" Decode.string
        let buckets = get.Required.Field "buckets" (Decode.list ThothIdentifiers.TermName.decode)
        (columnName, buckets)
    )

let private aggregationFilterEncoder (columnName, buckets) =
    Encode.object [ "columnName", Encode.string columnName
                    "buckets", List.map ThothIdentifiers.TermName.encode buckets |> Encode.list ]

let private orderByDecoder =
    Decode.object (fun get ->
        let expression = get.Required.Field "expression" QueryExpression.decode
        let ordering = get.Required.Field "ordering" Ordering.decode
        (expression, ordering)
    )

let private orderByEncoder (expression, ordering) =
    Encode.object [ "expression", QueryExpression.encode expression
                    "ordering", Ordering.encode ordering ]


module DataQuery =

    let decode : Decoder<DataQuery> =
        Decode.object (fun get ->
            { DataQuery.Columns = get.Optional.Field "columns" (Decode.option (Decode.list Decode.string)) |> Option.defaultValue None
              Condition = get.Optional.Field "condition" (Decode.option QueryExpression.decode) |> Option.defaultValue None
              IncludeAggregations = get.Optional.Field "includeAggregations" Decode.bool |> Option.defaultValue false
              AggregationFilters =
                  get.Optional.Field "aggregationFilters" (Decode.list aggregationFilterDecoder)
                  |> Option.defaultValue []
              LimitOffset =
                { Offset = get.Optional.Field "offset" (Decode.option Decode.int) |> Option.defaultValue None
                  Limit = get.Optional.Field "limit" (Decode.option Decode.int) |> Option.defaultValue None }
              OrderBy =
                  get.Optional.Field "orderBy" (Decode.list orderByDecoder)
                  |> Option.defaultValue []
            })

    let encode (dataQuery : DataQuery) =
        let aggregationFilterEncoder (columnName, buckets) =
            Encode.object [ "columnName", Encode.string columnName
                            "buckets", List.map ThothIdentifiers.TermName.encode buckets |> Encode.list ]
        let orderByEncoder (expression, ordering) =
            Encode.object [ "expression", QueryExpression.encode expression
                            "ordering", Ordering.encode ordering ]
        Encode.object [ "columns", Encode.option (List.map Encode.string >> Encode.list) dataQuery.Columns
                        "condition", Encode.option QueryExpression.encode dataQuery.Condition
                        "includeAggregations", Encode.bool dataQuery.IncludeAggregations
                        "aggregationFilters",
                            dataQuery.AggregationFilters
                            |> List.map aggregationFilterEncoder
                            |> Encode.list
                        "offset", Encode.option Encode.int dataQuery.LimitOffset.Offset
                        "limit", Encode.option Encode.int dataQuery.LimitOffset.Limit
                        "orderBy", dataQuery.OrderBy |> List.map orderByEncoder |> Encode.list ]

    let fromString dataset = Utils.fromStringResult (DataQuery.ToDomain dataset) decode
    let toString = Utils.toString DataQuery.FromDomain encode
    let toStringPretty = Utils.toStringPretty DataQuery.FromDomain encode

module DatasetQueryColumn =
    let decode =
        Decode.object (fun get ->
            { Name = get.Required.Field "name" Decode.string
              JsonPath = get.Required.Field "jsonPath" ThothIdentifiers.JsonPath.decode
              DataType =
                get.Optional.Field "dataType" (Decode.option Decode.string)
                |> Option.defaultValue None
                |> Option.map Transfer.Types.DataTypeName
            })
    let encode (column : DatasetQueryColumn) =
        Encode.object [
            "name", Encode.string column.Name
            "jsonPath", ThothIdentifiers.JsonPath.encode column.JsonPath
            "dataType",
                column.DataType
                |> Option.map (fun (Transfer.Types.DataTypeName name) -> name)
                |> Encode.option Encode.string
        ]

    let fromStringList = Utils.fromStringResult (List.traverseResultM DatasetQueryColumn.ToDomain) (Decode.list decode)

module DatasetQuery =

    let decode : Decoder<DatasetQuery> =
        Decode.object (fun get ->
            { DatasetQuery.Columns = get.Optional.Field "columns" (Decode.option (Decode.list DatasetQueryColumn.decode)) |> Option.defaultValue None
              Condition = get.Optional.Field "condition" (Decode.option QueryExpression.decode) |> Option.defaultValue None
              IncludeDescription = get.Optional.Field "includeDescription" Decode.bool |> Option.defaultValue false
              IncludeSchema = get.Optional.Field "includeSchema" Decode.bool |> Option.defaultValue false
              IncludeMetadata = get.Optional.Field "includeMetadata" Decode.bool |> Option.defaultValue false
              IncludeAggregations = get.Optional.Field "includeAggregations" Decode.bool |> Option.defaultValue false
              AggregationFilters =
                  get.Optional.Field "aggregationFilters" (Decode.list aggregationFilterDecoder)
                  |> Option.defaultValue []
              LimitOffset =
                { Offset = get.Optional.Field "offset" (Decode.option Decode.int) |> Option.defaultValue None
                  Limit = get.Optional.Field "limit" (Decode.option Decode.int) |> Option.defaultValue None }
              OrderBy =
                  get.Optional.Field "orderBy" (Decode.list orderByDecoder)
                  |> Option.defaultValue []
              LatestOnly = get.Optional.Field "latestOnly" Decode.bool |> Option.defaultValue true
            })

    let encode (datasetQuery : DatasetQuery) =
        Encode.object [ "columns", Encode.option (List.map DatasetQueryColumn.encode >> Encode.list) datasetQuery.Columns
                        "condition", Encode.option QueryExpression.encode datasetQuery.Condition
                        "includeDescription", Encode.bool datasetQuery.IncludeDescription
                        "includeSchema", Encode.bool datasetQuery.IncludeSchema
                        "includeMetadata", Encode.bool datasetQuery.IncludeMetadata
                        "includeAggregations", Encode.bool datasetQuery.IncludeAggregations
                        "aggregationFilters",
                            datasetQuery.AggregationFilters
                            |> List.map aggregationFilterEncoder
                            |> Encode.list
                        "offset", Encode.option Encode.int datasetQuery.LimitOffset.Offset
                        "limit", Encode.option Encode.int datasetQuery.LimitOffset.Limit
                        "orderBy", datasetQuery.OrderBy |> List.map orderByEncoder |> Encode.list
                        "latestOnly", Encode.bool datasetQuery.LatestOnly ]

    let toString = Utils.toString DatasetQuery.FromDomain encode
    let toStringPretty = Utils.toString DatasetQuery.FromDomain encode
    let fromString = Utils.fromStringResult DatasetQuery.ToDomain decode

module Aggregation =

    let decode : Decoder<Aggregation> =
        Decode.object (fun get ->
            { Buckets = get.Required.Field "buckets" (Decode.list ThothTypes.AggregationBucket.decode) })

    let encode (data : Aggregation) =
        Encode.object [ "buckets",
                        data.Buckets
                        |> List.map ThothTypes.AggregationBucket.encode
                        |> Encode.list ]

module DataResult =

    let decode (columns : Types.Column list) : Decoder<DataResult> =

        let columnDecoders : Decoder<Map<string, Value>> =
            Decode.object (fun get ->
                columns
                |> List.choose (fun column ->
                    get.Optional.Field column.Name (ThothValues.Value.typedDecode column.DataType)
                    |> Option.map (fun value -> (column.Name, value)))
                |> Map.ofList)

        Decode.object (fun get ->
            { Id = get.Required.Field "id" Decode.int
              Data = get.Required.Field "data" columnDecoders })

    let encode (result : DataResult) =
        Encode.object [
            "id", Encode.int result.Id
            "data",
                result.Data
                |> Map.map (fun _ -> ThothValues.Value.encode)
                |> Encode.dict
        ]

module DataQueryResponse =

    let decode (schema : Types.Schema) : Decoder<DataQueryResponse> =

        let columns =
            schema.Columns
            |> List.ofArray

        Decode.object (fun get ->
            { Total = get.Required.Field "total" Decode.int
              Offset = get.Required.Field "offset" Decode.int
              Limit = get.Required.Field "limit" (Decode.option Decode.int)
              Results = get.Required.Field "results" (Decode.list (DataResult.decode columns))
              Aggregations = get.Optional.Field "aggregations" (Decode.dict Aggregation.decode)
            })

    let encode (data : DataQueryResponse) =
        let aggregationsObject =
            match data.Aggregations with
            | None -> []
            | Some aggregations -> [
                "aggregations",
                    aggregations
                    |> Map.map (fun _ -> Aggregation.encode)
                    |> Encode.dict
             ]
        Encode.object ([
            "total", Encode.int data.Total
            "offset", Encode.int data.Offset
            "limit", Encode.option Encode.int data.Limit
            "results",
                data.Results
                |> List.map DataResult.encode
                |> Encode.list
        ] @ aggregationsObject)
    let fromString schema = Utils.fromString DataQueryResponse.ToDomain (decode schema)
    let toString = Utils.toString DataQueryResponse.FromDomain encode
    let toStringPretty = Utils.toStringPretty DataQueryResponse.FromDomain encode

module DatasetResult =

    let decode : Decoder<DatasetResult> =

        Decode.object (fun get ->
            { Id = get.Required.Field "id" ThothIdentifiers.PublishedDatasetIdAndVersion.decode
              Name = get.Required.Field "name" Decode.string
              Description = get.Optional.Field "description" (Decode.option Decode.string) |> Option.defaultValue None
              Created = get.Required.Field "created" Decode.datetimeOffset
              RowCount = get.Required.Field "rowCount" Decode.int
              Schema = get.Optional.Field "schema" (Decode.option ThothTypes.Schema.decode) |> Option.defaultValue None
              Metadata = get.Optional.Field "metadata" (Decode.option ThothDatasets.Metadata.decode) |> Option.defaultValue None
              Columns = get.Required.Field "columns" (Decode.keyValuePairs ThothValues.Value.decode) } )

    let encode (result : DatasetResult) =
        Encode.object [
            "id", ThothIdentifiers.PublishedDatasetIdAndVersion.encode result.Id
            "name", Encode.string result.Name
            "description", Encode.option Encode.string result.Description
            "created", Encode.datetimeOffset result.Created
            "rowCount", Encode.int result.RowCount
            "schema", Encode.option ThothTypes.Schema.encode result.Schema
            "metadata", Encode.option ThothDatasets.Metadata.encode result.Metadata
            "columns",
                result.Columns
                |> List.map (fun (k, v) -> (k, ThothValues.Value.encode v))
                |> Encode.object
        ]

module DatasetQueryResponse =

    let decode : Decoder<DatasetQueryResponse> =

        Decode.object (fun get ->
            { Total = get.Required.Field "total" Decode.int
              Offset = get.Required.Field "offset" Decode.int
              Limit = get.Required.Field "limit" (Decode.option Decode.int)
              Results = get.Required.Field "results" (Decode.list DatasetResult.decode)
              Aggregations = get.Optional.Field "aggregations" (Decode.dict Aggregation.decode)
            })

    let encode (data : DatasetQueryResponse) =
        let aggregationsObject =
            match data.Aggregations with
            | None -> []
            | Some aggregations -> [
                "aggregations",
                    aggregations
                    |> Map.map (fun _ -> Aggregation.encode)
                    |> Encode.dict
             ]
        Encode.object ([
            "total", Encode.int data.Total
            "offset", Encode.int data.Offset
            "limit", Encode.option Encode.int data.Limit
            "results",
                data.Results
                |> List.map DatasetResult.encode
                |> Encode.list
        ] @ aggregationsObject)

    let fromString = Utils.fromStringResult DatasetQueryResponse.ToDomain decode

// encoder/decoder for email wrapped into object; used for the remove user endpoint
module Email =
    let encode (email : Email) =
        Encode.object [ "email", ThothIdentifiers.Email.encode email ]

    let decode =
        Decode.field "email" ThothIdentifiers.Email.decode

    let toString = Utils.toString id encode
    let fromString = Utils.fromString id decode

// encoder/decoder for group name wrapped into object; for the remove-group endpoint
module WrappedAuthGroup =
    let encode (group : string) =
        Encode.object [ "name", Encode.string group ]

    let decode : Decoder<string> =
        Decode.field "name" Decode.string

    let toString = Utils.toString id encode
    let fromString = Utils.fromString id decode

// encoder/decoder for is_public wrapped into object; for the change-visibility endpoint
module WrappedIsPublic =
    let encode (isPublic : bool) =
        Encode.object [ "isPublic", Encode.bool isPublic ]

    let decode : Decoder<bool> =
        Decode.field "isPublic" Decode.bool

    let fromString = Utils.fromString id decode
    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode

module AuthUser =
    let encode (user : AuthUser) =
        Encode.object [ "email", Email.encode user.Email
                        "canWrite", Encode.bool user.CanWrite ]
    let decode =
        Decode.object (fun get ->
            { AuthUser.Email = get.Required.Field "email" Email.decode
              AuthUser.CanWrite = get.Required.Field "canWrite" Decode.bool })

    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode
    let fromString = Utils.fromString id decode

module AuthGroup =
    let encode (group : AuthGroup) =
        Encode.object [ "name", Encode.string group.Name
                        "canWrite", Encode.bool group.CanWrite ]

    let decode =
        Decode.object (fun get ->
            { AuthGroup.Name = get.Required.Field "name" Decode.string
              AuthGroup.CanWrite = get.Required.Field "canWrite" Decode.bool })

    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode
    let fromString = Utils.fromString id decode

module DatasetPermissions =
    let encode (permissions : DatasetPermissions) =
        Encode.object [ "id", ThothIdentifiers.DatasetId.encode permissions.Id
                        "isPublic", Encode.bool permissions.IsPublic
                        "users", permissions.Users |> List.map AuthUser.encode |> Encode.list
                        "groups", permissions.Groups |> List.map AuthGroup.encode |> Encode.list ]

    let decode : Decoder<DatasetPermissions> =
        Decode.object (fun get ->
            { Id = get.Required.Field "id" ThothIdentifiers.DatasetId.decode
              IsPublic = get.Required.Field "isPublic" Decode.bool
              Users = get.Required.Field "users" (Decode.list AuthUser.decode)
              Groups = get.Required.Field "groups" (Decode.list AuthGroup.decode) })

    let fromString = Utils.fromString id decode
    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode

module OidcConfig =
    let encode (config : OidcConfig) =
        Encode.object [ "domain", Encode.string config.Domain
                        "audience", Encode.string config.Audience
                        "nativeClientId", Encode.string config.NativeClientId
                        "webClientId", Encode.string config.WebClientId ]

    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode

module ErrorResponse =
    let encode (errorResponse : ErrorResponse) =
        let errorType = function
            | EdelweissData.Base.Errors.InternalServerError -> "InternalServerError"
            | EdelweissData.Base.Errors.TooMuchDataRequested -> "TooMuchDataRequested"
            | EdelweissData.Base.Errors.Unauthorized -> "Unauthorized"
            | EdelweissData.Base.Errors.Forbidden -> "Forbidden"
            | EdelweissData.Base.Errors.NotFound -> "NotFound"
            | EdelweissData.Base.Errors.BadRequest -> "BadRequest"

        Encode.object [ "errorType", Encode.option Encode.string (Option.map errorType errorResponse.ErrorType)
                        "message", Encode.string errorResponse.Message ]

    let decode : Decoder<ErrorResponse> =
        Decode.object (fun get ->
            let errorType =
                match get.Optional.Field "errorType" Decode.string with
                | Some "InternalServerError" -> Some EdelweissData.Base.Errors.InternalServerError
                | Some "TooMuchDataRequested" -> Some EdelweissData.Base.Errors.TooMuchDataRequested
                | Some "Unauthorized" -> Some EdelweissData.Base.Errors.Unauthorized
                | Some "Forbidden" -> Some EdelweissData.Base.Errors.Forbidden
                | Some "NotFound" -> Some EdelweissData.Base.Errors.NotFound
                | Some "BadRequest" -> Some EdelweissData.Base.Errors.BadRequest
                | Some _ | None -> None

            { Message = get.Required.Field "message" Decode.string
              ErrorType = errorType }
        )

    let toString = Utils.toString id encode
    let toStringPretty = Utils.toStringPretty id encode
    let fromString = Utils.fromString id decode
