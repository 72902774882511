module Utils

open Elmish.SweetAlert

let consoleLog msg = Browser.Dom.console.log msg
let consoleError msg = Browser.Dom.console.error msg

let convertFilenameToName (input : string) =

    let uppercase (input : string) =
        match input.Length with
        | 0 -> input
        | 1 -> input.ToUpper()
        | _ -> input.[0..0].ToUpper() + input.Substring(1)

    let removeExtension (input : string) =
        System.Text.RegularExpressions.Regex.Replace(input, @"\.[\w\d]+$", "")

    let stripChars (input : string) =
        System.Text.RegularExpressions.Regex.Replace(input, @"[_-]+", " ")

    let trim (input : string) =
        input.Trim()

    input
    |> trim
    |> removeExtension
    |> stripChars
    |> uppercase

let toastMsg toastType msg =
    ToastAlert(msg)
        .Position(AlertPosition.TopEnd)
        .Type(toastType)
        .ConfirmButton(false)
        .Timeout(5000)
    |> SweetAlert.Run

let toastInfo msg = toastMsg AlertType.Info msg
let toastError msg = toastMsg AlertType.Error msg

let alertError title (msg: string) =
    let threshold = 400
    let errorMsg = if msg.Length >= threshold then msg.Substring(0, threshold) + "..." else msg
    SimpleAlert(errorMsg)
        .Title(title)
        .Type(AlertType.Error)
    |> SweetAlert.Run

let alertSuccess title msg =
    SimpleAlert(msg)
        .Title(title)
        .Type(AlertType.Success)
    |> SweetAlert.Run

open Fable.Core
open Fable.Core.JsInterop
open Fetch
open Fable.Core.JS

/// Retrieves data from the specified resource. Does not fail even if `response.Ok` evals to false.
let fetch (url: string) (init: RequestProperties list) : Promise<Response> =
    let requestProps = keyValueList CaseRules.LowerFirst init :?> RequestInit
    GlobalFetch.fetch(RequestInfo.Url url, requestProps)