module EdelweissData.Thoth.Datasets

// ❗👀 Make sure that the code below is the same in EdelweissData.Thoth.Net and EdelweissData.Thoth.Fable

open EdelweissData.Base
open EdelweissData.Base.Transfer.Datasets
open Thoth.Json
open FsToolkit.ErrorHandling
module ThothIdentifiers = EdelweissData.Thoth.Identifiers
module ThothTypes = EdelweissData.Thoth.Types

module Metadata =

    let decode path obj =
        Ok (Datasets.MetadataContent (Encode.toString 2 obj))

    let encode (Datasets.MetadataContent metadata : Datasets.Metadata) =
        match Decode.fromString (fun path obj -> Ok obj) metadata with
        | Ok obj -> obj
        // We unsafely get rid of any errors because we need our encoder
        // to return a JsonValue, not Result<JsonValue, string>.
        | Error msg -> failwith msg

module InProgressDataset =

    let decode : Decoder<InProgressDataset> =
        Decode.object (fun get ->
            { Id = get.Required.Field "id" ThothIdentifiers.InProgressDatasetId.decode
              Name = get.Required.Field "name" Decode.string
              Schema = get.Optional.Field "schema" ThothTypes.Schema.decode
              Created = get.Required.Field "created" Decode.datetimeOffset
              Description = get.Required.Field "description" Decode.string
              Metadata = get.Required.Field "metadata" Metadata.decode
              DataSource = get.Optional.Field "dataSource" ThothIdentifiers.PublishedDatasetIdAndVersion.decode } : InProgressDataset)

    let encode (inProgressDataset : InProgressDataset) =
        Encode.object [ "id", ThothIdentifiers.InProgressDatasetId.encode inProgressDataset.Id
                        "name", Encode.string inProgressDataset.Name
                        "schema", Encode.option ThothTypes.Schema.encode inProgressDataset.Schema
                        "created", Encode.datetimeOffset inProgressDataset.Created
                        "description", Encode.string inProgressDataset.Description
                        "metadata", Metadata.encode inProgressDataset.Metadata
                        "dataSource", Encode.option ThothIdentifiers.PublishedDatasetIdAndVersion.encode inProgressDataset.DataSource ]
    let toString = Utils.toString InProgressDataset.FromDomain encode
    let toStringPretty = Utils.toStringPretty InProgressDataset.FromDomain encode
    let toStringList = Utils.toString (List.map InProgressDataset.FromDomain) (List.map encode >> Encode.list)
    let toStringListPretty = Utils.toStringPretty (List.map InProgressDataset.FromDomain) (List.map encode >> Encode.list)
    let fromString = Utils.fromStringResult InProgressDataset.ToDomain decode
    let fromStringList =
        Utils.fromStringResult
            (List.traverseResultM InProgressDataset.ToDomain)
            (Decode.list decode)

module PublishedDataset =

    let decode : Decoder<PublishedDataset> =
        Decode.object (fun get ->
            { Id = get.Required.Field "id" ThothIdentifiers.PublishedDatasetIdAndVersion.decode
              Name = get.Required.Field "name" Decode.string
              Schema = get.Required.Field "schema" ThothTypes.Schema.decode
              Created = get.Required.Field "created" Decode.datetimeOffset
              RowCount = get.Required.Field "rowcount" Decode.int
              Description = get.Required.Field "description" Decode.string
              Metadata = get.Required.Field "metadata" Metadata.decode } : PublishedDataset)

    let encode (publishedDataset : PublishedDataset) =
        Encode.object [ "id", ThothIdentifiers.PublishedDatasetIdAndVersion.encode publishedDataset.Id
                        "name", Encode.string publishedDataset.Name
                        "schema", ThothTypes.Schema.encode publishedDataset.Schema
                        "rowcount", Encode.int publishedDataset.RowCount
                        "created", Encode.datetimeOffset publishedDataset.Created
                        "description", Encode.string publishedDataset.Description
                        "metadata", Metadata.encode publishedDataset.Metadata ]

    let toString = Utils.toString PublishedDataset.FromDomain encode
    let toStringPretty = Utils.toStringPretty PublishedDataset.FromDomain encode
    let fromString = Utils.fromStringResult PublishedDataset.ToDomain decode
