module EdelweissData.Base.Identifiers

open System
open FsToolkit.ErrorHandling
type Regex = System.Text.RegularExpressions.Regex
type RegexOptions = System.Text.RegularExpressions.RegexOptions

type Version =
    | Version of int

    override this.ToString() =
        match this with
        | Version version -> version.ToString()

    member this.Unwrap() =
        match this with
        | Version version -> version

    static member Parse(str : string) =
        match Int32.TryParse str with
        | true, number when number >= 0 -> Ok(Version number)
        | true, number -> Error(EdelweissData.Base.Errors.InvalidDatasetVersion str)
        | false, _ -> Error(EdelweissData.Base.Errors.InvalidDatasetVersion str)

module Helper =
    let internal (|CaseInsensitiveEqual|_|) (str:string) arg =
        if String.Compare(str, arg, StringComparison.OrdinalIgnoreCase) = 0
          then Some() else None

type VersionOrLatest =
    | ConcreteVersion of Version
    | Latest

    override this.ToString() =
        match this with
        | ConcreteVersion version -> version.ToString()
        | Latest -> "latest"

    static member Parse(str : string) =
        match str with
        | Helper.CaseInsensitiveEqual "latest" -> Ok Latest
        | text -> Version.Parse str |> Result.map ConcreteVersion


type DatasetId =
    | DatasetId of System.Guid

    override this.ToString() =
        match this with
        | DatasetId guid -> guid.ToString()

    member this.Unwrap() =
        match this with
        | DatasetId guid -> guid



type InProgressDatasetId =
    | InProgressDatasetId of System.Guid

    override this.ToString() =
        match this with
        | InProgressDatasetId guid -> guid.ToString()

    member this.Unwrap() =
        match this with
        | InProgressDatasetId guid -> guid

    member this.AsDatasetId() =
        match this with
        | InProgressDatasetId guid -> DatasetId guid

type PublishedDatasetId =
    | PublishedDatasetId of System.Guid

    override this.ToString() =
        match this with
        | PublishedDatasetId guid -> sprintf "%O" guid

    member this.Unwrap() =
        match this with
        | PublishedDatasetId guid -> guid

    member this.AsDatasetId() =
        match this with
        | PublishedDatasetId guid -> DatasetId guid

    static member Parse(str : string) =
        str
        |> EdelweissData.Base.Utils.parseGuid
        |> Result.map PublishedDatasetId

// Is this the correct place to define user id? It is never exposed outside of the server.
type AuthUserId =
    | AuthUserId of System.Guid

    override this.ToString() =
        match this with
        | AuthUserId guid -> guid.ToString()

    member this.Unwrap() =
        match this with
        | AuthUserId guid -> guid

type AuthGroupId =
    | AuthGroupId of System.Guid

    override this.ToString() =
        match this with
        | AuthGroupId guid -> guid.ToString()

    member this.Unwrap() =
        match this with
        | AuthGroupId guid -> guid


type PublishedDatasetIdAndVersionOrLatest =
    { Id : PublishedDatasetId
      VersionOrLatest : VersionOrLatest }
    // Documented version of the regex
    // Since Fable/JS regex does not support named groups, the actual regex uses numberd groups instead of the named ones.
    //   """^                         # Beginning of string
    //         \s*                    # Ignore leading whitespace
    //         \{?                    # Ignore optional {
    //         (?<guid>               # Start named group guid
    //             [0-9a-fA-F]{8}     # 8 hex characters
    //             \-                 # dash
    //             [0-9a-fA-F]{4}     # 4 hex characters
    //             \-                 # dash
    //             [0-9a-fA-F]{4}     # 4 hex characters
    //             \-                 # dash
    //             [0-9a-fA-F]{4}     # 4 hex characters
    //             \-                 # dash
    //             [0-9a-fA-F]{12}    # 12 hex characters
    //         )                      # close group guid
    //         \}?                    # optional closing }
    //         :                      # : as seperator between guid and version
    //         (?<version>            # start named group version
    //             ( \d+              # any number of digits
    //             | latest ))        # or the verbatim string "latest"
    //         \s*                    # Ignore trailing whitespace
    //         $"""
    static member private ShortStringRegex =
        Regex("""^\s*\{?([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})\}?:(\d+|latest)\s*$""", (RegexOptions.IgnoreCase))
    member this.ToShortString() =
        sprintf "%O:%O" this.Id this.VersionOrLatest

    static member ParseShortString(str : string) =
        result {
            let! regexmatch =
                PublishedDatasetIdAndVersionOrLatest.ShortStringRegex.Match(str)
                |> (fun regexmatch -> if regexmatch.Success then Ok regexmatch; else Error <| EdelweissData.Base.Errors.CouldNotParseDataType (sprintf "%s could not be parsed as a publised dataset id with version" str))

            let! datasetid = regexmatch.Groups.[1].Value |> EdelweissData.Base.Utils.parseGuid
            let! version = regexmatch.Groups.[2].Value |> VersionOrLatest.Parse
            return
                { Id = PublishedDatasetId datasetid
                  VersionOrLatest = version }
        }


type PublishedDatasetIdAndVersion =
    { Id : PublishedDatasetId
      Version : Version }

    static member Create(id, version) =
        { Id = id
          Version = version }

    member public this.AsIdWithVersionOrLatest =
        { Id = this.Id
          VersionOrLatest = ConcreteVersion this.Version }






type TermName =
    | TermId of string
    | Null

    override this.ToString() =
        match this with
        | TermId id -> id
        | Null -> "NULL"

type FilePath =
    | FilePath of string

    override this.ToString() =
        match this with
        | FilePath path -> path

    member this.Unwrap() =
        match this with
        | FilePath path -> path

type DirectoryPath =
    | DirectoryPath of string

    override this.ToString() =
        match this with
        | DirectoryPath path -> path

    member this.Unwrap() =
        match this with
        | DirectoryPath path -> path

type Url =
    | Url of string

    override this.ToString() =
        match this with
        | Url path -> path

    member this.Unwrap() =
        match this with
        | Url path -> path

module UrlHelpers =
    let join(baseUrl : Url, route : string) =
        let (Url baseString) = baseUrl
        Url (baseString.TrimEnd([|'/'|]) + "/" + route.TrimStart([|'/'|]))


type Email =
  | Email of string

    member this.Unwrap() =
        match this with
        | Email email -> email

type JsonPath =
  | JsonPath of string

    member this.Unwrap() =
        match this with
        | JsonPath jsonPath -> jsonPath
