module EdelweissData.Base.Utils

// ❗👀 This file is used from Fable projects and functions/types used here
// have to be transpile-able to JS. If you make modifications here, please
// check that the SAFE Client apps with Fable still compile. Thanks!

open FsToolkit.ErrorHandling

let parseGuid (str : string) : Result<System.Guid, Errors.Error> =
    match System.Guid.TryParse(str) with
    | (true, guid) -> Ok guid
    | (false, _) -> Error(Errors.InvalidUUID str)

let (|CaseInsensitiveEqual|_|) (str : string) (arg : string) =
    if str.Equals(arg, System.StringComparison.InvariantCultureIgnoreCase) then Some()
    else None

let (|StartsWith|_|) (str : string) (arg : string) =
    if arg.StartsWith(str, System.StringComparison.CurrentCultureIgnoreCase) then Some(arg.Substring(str.Length))
    else None


let decodingError fmt =
  let k msg = result {
    return! Error (EdelweissData.Base.Errors.DecodingError msg)
  }
  Printf.ksprintf k fmt
